#profile {
  padding-bottom: 200px;

  .row > div {
    margin-bottom: 20px;

    img {
      width: 100%;
      max-width: 246px;
      height: auto;
    }

    #profilePicture {
      border-radius: 50%;
      margin-bottom: 20px;
      border: solid 2px $secondary-color;
      padding: 4px;
    }
  }
}

@media (max-width: #{$desktop-width - 1px}) {
  #profile {
    .row * {
      text-align: center;
    }
  }
}
