// Variable Setup
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;600;700&display=swap');

$font-stack: 'Open Sans', sans-serif !important;
$primary-color: #094567;
$secondary-color: #22a39f;
$text-color: #434242;

$tablet-width: 768px;
$desktop-width: 1024px;

body {
  background: #676767;
  font-size: 15px;
  line-height: 1.64;
}

html,
body {
  color: $text-color;
  font-family: $font-stack;
  height: 100%;
}

h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6,
strong,
b {
  font-family: $font-stack;
  margin-bottom: 20px;
}

h1,
h4 {
  font-weight: 700;
  margin-bottom: 10px;
}

h2,
h3 {
  font-weight: 300;
  color: $secondary-color;
}

h2 {
  font-size: 55px;
  text-align: center;
}

h3 {
  font-size: 28px;
}

a {
  color: #337ab7;
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
}

ul.no-bullets {
  list-style-type: none;
  margin: 0px;
  padding: 0px;
}

p.lead {
  opacity: 0.75;
}

.seperator {
  margin-left: 6px;
  margin-right: 6px;
}

#loader-wrapper {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #222222;
  z-index: 1000;

  #loader {
    position: absolute;
    margin: 0 auto;
    left: 50%;
    top: 48%;
    z-index: 1001;
  }
}

.loaded {
  #loader-wrapper {
    opacity: 0;
    -webkit-transition: all 0.5s ease-out;
    transition: all 0.5s ease-out;

    #loader {
      opacity: 0;
      -webkit-transition: all 0.3s ease-out;
      transition: all 0.3s ease-out;
    }
  }
}

.background-black {
  background: rgba(0, 0, 0, 1);
  color: #fff;

  hr {
    margin: 40px 0px;
    border-color: #fff;
    opacity: 0.5;
    filter: alpha(opacity=50);
  }

  h2,
  h3 {
    color: #fff;
  }

  p.lead {
    text-align: center;
    color: #fff;

    a {
      color: #ffffff;
      text-decoration: underline;

      &:hover {
        text-decoration: none;
      }
    }
  }

  .overlay {
    position: relative;
    background: rgba(0, 0, 0, 0.8);
  }
}

.background-white {
  background: rgba(255, 255, 255, 1);

  hr {
    margin: 40px 0px;
    border-color: #727878;
    opacity: 0.2;
    filter: alpha(opacity=20);
  }

  h2,
  h3 {
    color: $primary-color;
  }

  p.lead {
    text-align: center;
    color: #000;

    a {
      color: #000;
      text-decoration: underline;

      &:hover {
        text-decoration: none;
      }
    }
  }

  .overlay {
    position: relative;
    background: rgba(255, 255, 255, 0.8);
  }
}

.background-gray {
  background: rgba(67, 66, 66, 1);

  hr {
    margin: 40px 0px;
    border-color: #727878;
    opacity: 0.2;
    filter: alpha(opacity=20);
  }

  h2,
  h3 {
    color: $secondary-color;
  }

  p.lead {
    text-align: center;
    color: #fff;

    a {
      color: #ffffff;
      text-decoration: underline;

      &:hover {
        text-decoration: none;
      }
    }
  }

  .overlay {
    position: relative;
    background: rgba(67, 66, 66, 0.8);
  }
}

.container {
  max-width: 1280px;
  padding: 40px 180px;
}

.section {
  margin-top: 1px;
}

.project-referal {
  margin-top: 40px;

  p {
    font-style: italic;
    color: #999999;
  }
}

.btn-primary {
  background-color: #094e71;
  border-color: #093657;

  &:hover {
    background-color: #093657;
    border-color: #093657;
  }
}

.errorPage {
  .jumbotron {
    .container {
      h1 {
        font-size: 100px;
      }

      h2 {
        font-size: 40px;
      }
    }
  }
}

@keyframes wiggle {
  0% {
    margin-top: 20px;
  }

  50% {
    margin-top: 28px;
  }

  100% {
    margin-top: 20px;
  }
}

/*----------------*/
/***** EFFECT *****/
/*----------------*/
figure.effect {
  background: #000;
  color: #fff;

  img {
    width: -webkit-calc(100% + 20px);
    width: calc(100% + 20px);
    -webkit-transition: opacity 0.35s, -webkit-transform 0.35s;
    transition: opacity 0.35s, transform 0.35s;
    -webkit-transform: translate3d(-10px, 0, 0);
    transform: translate3d(-10px, 0, 0);
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
  }

  figcaption {
    text-align: left;
  }

  h3 {
    position: relative;
    overflow: hidden;
    padding-bottom: 20px;
    opacity: 0;
    -webkit-transition: opacity 0.35s, -webkit-transform 0.35s;
    transition: opacity 0.35s, transform 0.35s;

    &::after {
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 2px;
      background: #fff;
      content: '';
      -webkit-transition: -webkit-transform 0.35s;
      transition: transform 0.35s;
      -webkit-transform: translate3d(-100%, 0, 0);
      transform: translate3d(-100%, 0, 0);
    }
  }

  p {
    padding-top: 10px;
    opacity: 0;
    -webkit-transition: opacity 0.35s, -webkit-transform 0.35s;
    transition: opacity 0.35s, transform 0.35s;
    -webkit-transform: translate3d(100%, 0, 0);
    transform: translate3d(100%, 0, 0);
  }

  &:hover {
    img {
      opacity: 0.2;
      -webkit-transform: translate3d(0, 0, 0);
      transform: translate3d(0, 0, 0);
    }

    h3 {
      opacity: 1;

      &::after {
        -webkit-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0);
      }
    }

    p {
      opacity: 1;
      -webkit-transform: translate3d(0, 0, 0);
      transform: translate3d(0, 0, 0);
    }
  }
}
