.section.experiences {
  background: no-repeat center right fixed url(../../assets/images/background.jpg);

  -webkit-background-size: 100%;
  -moz-background-size: 100%;
  -o-background-size: 100%;
  background-size: 100%;

  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
}

#experiences {
  .experience {
    margin-bottom: 20px;

    p > strong {
      display: block;
      padding-top: 8px;
      margin-bottom: 10px;
    }

    .about {
      font-size: 14px;
      font-style: italic;
    }

    .experience-details {
      display: block;
      margin-top: 6px;

      * {
        color: $secondary-color;
      }

      .location {
        .icon {
          margin-right: 5px;
        }
      }

      .link {
        .icon {
          margin-right: 5px;
        }
      }

      .seperator {
        color: #bcbcbc;
      }
    }
  }
}

@media (max-width: #{$desktop-width - 1px}) {
  #experiences {
    .experiences {
      .experience-details {
        .seperator {
          display: none;
        }

        & > span {
          display: block;
          margin-bottom: 6px;
        }
      }
    }
  }
}
