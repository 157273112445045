.section.projects {
  background: no-repeat center right fixed url(../../assets/images/web-development-coding.jpg);

  -webkit-background-size: 100%;
  -moz-background-size: 100%;
  -o-background-size: 100%;
  background-size: 100%;

  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
}

#projects {
  figure {
    position: relative;
    overflow: hidden;

    h3 {
      color: #fff;
      font-weight: 600;
      margin-bottom: 5px;
    }

    p > strong {
      width: 60px;
      display: inline-block;
      margin: 0px;
    }

    img {
      width: 110%;
    }

    figcaption {
      padding: 20px 30px;
      position: absolute;
      top: 0px;
      left: 0px;
      height: 100%;
      width: 100%;
    }

    a {
      z-index: 100;
      text-indent: 200%;
      white-space: nowrap;
      font-size: 0;
      opacity: 0;
      position: absolute;
      top: 0px;
      left: 0px;
      height: 100%;
      width: 100%;
    }

    .icon {
      background: #1c1d23;
      position: absolute;
      top: 0px;
      right: 0px;
      width: 44px;
      height: 44px;
      font-size: 24px;
      text-align: center;

      span {
        font-size: 18px;
        line-height: 18px;
        padding: 15px;
      }
    }
  }

  .row > div {
    margin-bottom: 30px;
  }
}

#sideprojects {
  figure {
    position: relative;
    overflow: hidden;

    h3 {
      color: #fff;
      font-weight: 600;
      margin-bottom: 5px;
    }

    p > strong {
      width: 60px;
      display: inline-block;
      margin: 0px;
    }

    img {
      width: 110%;
    }

    figcaption {
      padding: 20px 30px;
      position: absolute;
      top: 0px;
      left: 0px;
      height: 100%;
      width: 100%;
    }

    a {
      z-index: 100;
      text-indent: 200%;
      white-space: nowrap;
      font-size: 0;
      opacity: 0;
      position: absolute;
      top: 0px;
      left: 0px;
      height: 100%;
      width: 100%;
    }

    .icon {
      background: #1c1d23;
      position: absolute;
      top: 0px;
      right: 0px;
      width: 48px;
      height: 48px;

      span {
        font-size: 18px;
        line-height: 18px;
        padding: 15px;
      }
    }
  }

  .row > div {
    margin-bottom: 30px;
  }
}
