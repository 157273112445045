.jumbotron {
  display: flex;
  height: 100vh;
  align-items: center;
  justify-content: center;
  background: no-repeat center center fixed url(../../assets/images/webdev_background.jpg);
  -webkit-background-size: 100%;
  -moz-background-size: 100%;
  -o-background-size: 100%;
  background-size: 100%;

  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;

  -ms-filter: "progid:DXImageTransform.Microsoft.AlphaImageLoader(src='/assets/images/background.jpg', sizingMethod='scale')";
  filter: progid:DXImageTransform.Microsoft.AlphaImageLoader(src='/assets/images/background.jpg', sizingMethod='scale');
  margin-bottom: 0px;
  padding: 0px;

  .overlay {
    background-color: #000;
    position: absolute;
    top: 0px;
    bottom: 0px;
    left: 0px;
    right: 0px;
    opacity: 0.5;
    filter: alpha(opacity=75);
    z-index: 2;
  }

  .container {
    position: relative;
    z-index: 4;
    text-align: center;

    & > * {
      text-align: center;
      color: #ffffff;
    }

    h2 {
      padding-bottom: 20px;
      border-bottom: 1px solid #ffffff;
      border-bottom-color: rgba(255, 255, 255, 0.35);
      display: inline-block;
    }

    .lead {
      font-size: 28px;
      margin-top: 30px;

      a {
        color: #00fffa;
      }
    }
  }

  .scroll-down {
    height: 80px;
    width: 120px;
    display: block;
    position: absolute;
    bottom: 0px;
    right: 5%;
    background-color: #222222;
    text-align: center;
    z-index: 6;

    .icon {
      color: #ffffff;
      font-size: 30px;
      padding-right: 2px;
      margin-top: 20px;
      animation-name: wiggle;
      animation-duration: 1.2s;
      animation-timing-function: linear;
      animation-iteration-count: infinite;
      animation-direction: normal;
    }
  }
}

@media (max-width: #{$desktop-width - 1px}) {
  .jumbotron {
    &:before {
      bottom: 100px;
      left: 20px;
      right: 20px;
      top: 20px;
    }

    .container {
      top: 20%;
      padding-top: 0px;
    }

    .scroll-down {
      width: 100%;
      right: 0px;
    }
  }
}

@media only screen and (orientation: portrait) and (max-width: #{$desktop-width - 1px}) {
  .jumbotron {
    -webkit-background-size: auto 150%;
    background-attachment: scroll;
  }
}

@media only screen and (orientation: landscape) and (max-width: #{$desktop-width - 1px}) {
  .jumbotron {
    -webkit-background-size: 150% auto;
    background-attachment: scroll;

    .lead {
      display: none;
    }
  }
}
