.section.abilities {
  background: #eeeeee;
}

#abilities {
  ul > li {
    min-height: 30px;
    padding: 3px 0px;

    &:hover {
      background: #f2f2f2;

      .ability-score {
        .star-icon {
          margin-left: 2px;
        }
      }
    }

    .ability-title {
      display: inline-block;
      // width: 70%;

      .small {
        padding-left: 6px;
        font-size: 75%;
        color: rgba(0, 0, 0, 0.7);
      }
    }

    .ability-score {
      float: right;

      .star-icon {
        color: rgba(119, 119, 119, 0.45);
        font-size: 16px;
        -webkit-transition: all 0.3s;
        -moz-transition: all 0.3s;
        -o-transition: all 0.3s;
        transition: all 0.3s;
        &.filled {
          color: #ff9800;
        }
      }
    }
  }
}
