.section.interests {
  background: #cbcbcb;
}

#interests {
  img {
    width: 80%;
    height: auto;
  }

  .interest-content {
    padding: 40px 0px;
  }

  .left {
    float: left;
  }

  .right {
    float: right;

    img {
      float: right;
    }
  }

  .row {
    &:hover {
      .left img {
        -webkit-transform: rotate(-12deg);
        -moz-transform: rotate(-12deg);
        -ms-transform: rotate(-12deg);
        -o-transform: rotate(-12deg);
        transform: rotate(-12deg);
      }

      .right img {
        -webkit-transform: rotate(12deg);
        -moz-transform: rotate(12deg);
        -ms-transform: rotate(12deg);
        -o-transform: rotate(12deg);
        transform: rotate(12deg);
      }
    }
  }
}

@media (max-width: 1200px) {
  #interests {
    .interest-content {
      padding: 20px 0px;
    }
  }
}

@media (max-width: #{$desktop-width - 1px}) {
  #interests {
    .left,
    .right {
      float: none;
    }

    img {
      width: 50%;
      height: auto;
      float: none !important;
    }

    .interest-content {
      padding: 20px 0px;
    }

    & > * {
      text-align: center;
    }
  }
}
