.navbar {
  border: 0px;
  background-color: #222222;
  position: absolute;
  top: 100%;
  right: 5%;
  width: 120px;
  z-index: 999;
  border-radius: 0;
  padding: 0;

  .navbar-collapse {
    display: block !important;
    padding: 0px;
    max-height: none;

    .nav {
      float: none;
      display: block;
      margin: 0px;

      a {
        display: block;
        height: 80px;
        text-align: center;
        padding-top: 30px;
        font-weight: 600;
        color: #ffffff;
        &:focus {
          color: #ffffff;
        }
        &:hover {
          color: #999999;
          text-decoration: none;
        }

        &.current {
          background: $primary-color;
          color: #ffffff;
        }
      }
    }
  }
}

.navbar-default {
  .navbar-nav > .current > a,
  .navbar-nav > .current > a:hover,
  .navbar-nav > .current > a:focus {
    background: $primary-color;
    color: #ffffff;
  }

  .navbar-toggle {
    &:hover,
    &:focus {
      .icon-bar {
        background: #222222;
      }
    }
  }
}

.navbar.fixed {
  position: fixed;
  top: 60px;
}

@media (max-width: #{$desktop-width - 1px}) {
  .navbar {
    left: 0px;
    width: 100%;

    .nav {
      a {
        height: 60px;
        text-align: center;
        padding-top: 20px;
        font-weight: 600;
        color: #ffffff;
      }
    }
  }

  .navbar.fixed {
    top: 0px;
  }

  .container {
    padding: 60px 20px;
    min-width: 280px;
  }

  .hidden-phone {
    display: none;
  }
}
