#contact {
  padding-bottom: 100px;

  h2 {
    color: #ffffff;
  }

  .row * {
    color: #ffffff;
  }

  li {
    span.icon {
      font-size: 36px;
    }
  }

  a {
    display: block;
    line-height: 40px;
    margin-bottom: 20px;
    opacity: 0.6;
    filter: alpha(opacity=60);

    &:hover {
      opacity: 1;
      filter: alpha(opacity=100);
      text-decoration: none;
    }

    .icon {
      float: left;
      margin-right: 10px;
      width: 50px;
      font-size: 34px;
    }
  }
}

@media (max-width: #{$desktop-width - 1px}) {
  #contact {
    padding-bottom: 60px;

    a .icon {
      float: left;
      margin-right: 10px;
    }
  }
}
